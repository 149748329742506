import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, pathname }) => {
  const url = `http://blueskysa.com.ar/${pathname}`;

  return (
    <Helmet title={title}>
      <meta charSet="utf-8" />
      <html lang="es" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {url && <meta property="og:url" content={url} />}
      {title && <meta property="og:title" content={title} />}

      {description && <meta property="og:description" content={description} />}
    </Helmet>
  );
};

export default SEO;
